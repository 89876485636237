<template>
  <AppLayout>
    <main>
      <div
        class="page-header pb-10 page-header-dark bg-gradient-primary-to-secondary"
      >
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>Rates</span>
            </h1>
            <div class="page-header-subtitle ml-2">All Created Rates</div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">
            Rates
            <router-link to="/rates/newrate" class="btn btn-primary ml-auto"
              >Create Rate</router-link
            >
          </div>
          <div class="card-body">
            <div v-if="pageLoading">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/loader.gif"
              />
            </div>
            <div v-else-if="rates.length == 0">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/nodata.svg"
              />
              <p class="text-center">
                No data currently available
                <router-link to="/rates/newrate">Create New Rate</router-link>
                to continue
              </p>
            </div>
            <div v-else class="datatable table-responsive">
              <table
                class="table table-bordered table-hover"
                id="dataTable"
                cellspacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Broker</th>
                    <th>Type Of Cover</th>
                    <th>Rate</th>
                    <th>Cover Scope</th>
                    <th>Voyage Type</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>#</th>
                    <th>Broker</th>
                    <th>Type Of Cover</th>
                    <th>Rate</th>
                    <th>Cover Scope</th>
                    <th>Voyage Type</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </tfoot>
                <tbody>
                  <tr v-for="(rate, i) in rates" :key="rate.id">
                    <td>{{ i + 1 }}</td>
                    <td>{{ rate.party }}</td>
                    <td>{{ rate.typeofCovers }}</td>
                    <td>{{ rate.name }}</td>
                    <td>
                      {{
                        rate.cover == "MCI"
                          ? "MARINE SINGLE TRANSIT"
                          : rate.cover == "MOC"
                          ? "MARINE OPEN COVER"
                          : null
                      }}
                    </td>

                    <td>{{ rate.status }}</td>
                    <td>{{ rate.voyageType }}</td>
                    <td>
                      <router-link
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit Rate"
                        class="btn btn-datatable btn-transparent-dark mr-2"
                        :to="{ name: 'EditRate', params: { id: rate.id } }"
                      >
                        <i
                          class="bi bi-pencil"
                          style="font-size: 1rem"
                        ></i></router-link
                      ><button
                        data-toggle="tooltip"
                        title="Deactivate"
                        class="btn btn-datatable btn-icon btn-transparent-dark"
                      >
                        <i class="bi bi-x-circle" style="font-size: 1rem"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "@/stores/admin";

import $ from "jquery";

export default {
  name: "Rates",
  components: { AppLayout },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useAdminStore, ["pageLoading", "rates"]),
  },

  methods: {
    ...mapActions(useAdminStore, ["getAllRates"]),
  },

  created() {
    this.getAllRates().then(() => {
      $(document).ready(function () {
        $("#dataTable").DataTable();
      });
    });
    if (this.rates.length > 0) {
      $(document).ready(function () {
        $("#dataTable").DataTable();
      });
    }
  },
};
</script>
